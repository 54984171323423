// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titre-modale{
    font-size: 1.5vw;
    text-align: center;
}
.phrase-modale {
    font-size: 1vw;
}

.box-button-modale
{
    display: flex;
    justify-content: center;
    align-items: center;
}


.box-modale {
    border: 5px solid black;
    border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/css/modale.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,cAAc;AAClB;;AAEA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".titre-modale{\n    font-size: 1.5vw;\n    text-align: center;\n}\n.phrase-modale {\n    font-size: 1vw;\n}\n\n.box-button-modale\n{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n\n.box-modale {\n    border: 5px solid black;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
