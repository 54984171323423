import {Box, Grid, Link, TextField, Button, Typography} from '@mui/material';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './css/global.css';
import './css/connexion.css';
import { useAppContext } from './context/AppContext';
import { useUserContext } from './context/UserContext';

export default function Connexion() {
    const question = require('./assets/question.jpg');
    const personnage = require('./assets/personnage.jpg');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {isLogged, setIsLogged} = useUserContext();
    const {email, setEmail, password, setPassword} = useUserContext();
    const {settingApiAddress, apiAppKey, setBearerToken, bearerToken} = useAppContext();

    async function authentifier() {
        if (!email || !password) {
            console.error("Email et mot de passe sont requis");
            return;
        }
    
        const userData = await fetch(settingApiAddress + "users/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              password: password,
            //   appKey: apiAppKey,
            }),
            
          });

        if (userData.ok) {
            const data = await userData.json();
            setBearerToken(data.bearerToken);
            setIsLogged(true);
            navigate('/bienvenue');
        } else {
            setIsLogged(false);
            setError('Adresse courriel ou mot de passe invalide.');
        }
    }

    const submitForm = (event: React.FormEvent) => {
        event.preventDefault();
        if (validateFields()) {
            authentifier();
        }
        else {
            setError('Adresse courriel ou mot de passe invalide.');
            setIsLogged(false);
        }
    }
        
    const validateFields = () => {
        let isValid = true;

        // Vérifiez si le mot de passe est valide
        if (password.length <= 0) {
            isValid = false;
            setError('Veuillez entrer un mot de passe.');
        }
        // Vérifiez si l'email est valide
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            isValid = false;
            setError('Veuillez entrer une adresse courriel valide.');
        }

        if (isValid) {
        }
        return isValid;
    };

  return (
    <Box className="main">
        <Box display="flex" flexDirection="column" justifyContent="space-evenly">
            <Typography className="titre-texte"> Plateforme de formulaire d'indécision vocationelle </Typography>
                <Box className="box-form">
                    <img src={question} alt="Logo" className="img" />
                    <Box component="form" onSubmit={submitForm} className="form">
                    <Typography variant="h5" align="center"> S'authentifier</Typography>
                    <Typography variant="body2" align="center" color="error">{error}</Typography>
                        <TextField margin="normal" required fullWidth id="email" label="Adresse courriel" name="email" value={email} autoComplete="email" onChange={e => setEmail(e.target.value)} autoFocus/>
                        <TextField margin="normal" required fullWidth name="password" label="Mot de Passe" type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} autoComplete="current-password"/>
                        <Button type="submit" fullWidth variant="contained" className="button"> Se connecter </Button>
                        <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2"> Mot de passe oublié?</Link>
                        </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">Créer un compte</Link>
                            </Grid>
                        </Grid>
                    </Box>
                <img src={personnage} alt="Logo" className="img" />
                </Box>
        </Box>
    </Box>

  );
}