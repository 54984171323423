// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: white;
    border-bottom: 1px solid black;
  }
  
  .logo {
    width: 15%;
    min-width: 125px;
  }
  
  .nav-icon {
    color: black;
  }
  
  .menu-items {
    display: block;
  }

  
  .nav-button {
    color: black !important;
    display: block;
  }
  
  .avatar-tooltip {
    margin-top: 45px;
  }

  .texte-nav-button {
    text-decoration: none; 
    color: black;
    align-items: center;
  }
`, "",{"version":3,"sources":["webpack://./src/css/header.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,8BAA8B;EAChC;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;;EAGA;IACE,uBAAuB;IACvB,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":[".header {\n    background-color: white;\n    border-bottom: 1px solid black;\n  }\n  \n  .logo {\n    width: 15%;\n    min-width: 125px;\n  }\n  \n  .nav-icon {\n    color: black;\n  }\n  \n  .menu-items {\n    display: block;\n  }\n\n  \n  .nav-button {\n    color: black !important;\n    display: block;\n  }\n  \n  .avatar-tooltip {\n    margin-top: 45px;\n  }\n\n  .texte-nav-button {\n    text-decoration: none; \n    color: black;\n    align-items: center;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
