// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableau-resultat {
    background-color: lightgrey;
    width: 95vw !important;
}

.tableau-section {
    border: 1px solid black !important;
    font-size: 1vw !important; 
    padding-top: 1.30vh !important;
    padding-bottom: 1.30vh !important;
    max-width: 20vw;
}

.texte-centre {
    text-align: center !important;
}

.tableau-numero {
    margin: 1.5vw; 
}

.box-bouton-formulaire-resultat {
    margin-top: 2vh;
    display: flex;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/resultat.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,kCAAkC;IAClC,yBAAyB;IACzB,8BAA8B;IAC9B,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".tableau-resultat {\n    background-color: lightgrey;\n    width: 95vw !important;\n}\n\n.tableau-section {\n    border: 1px solid black !important;\n    font-size: 1vw !important; \n    padding-top: 1.30vh !important;\n    padding-bottom: 1.30vh !important;\n    max-width: 20vw;\n}\n\n.texte-centre {\n    text-align: center !important;\n}\n\n.tableau-numero {\n    margin: 1.5vw; \n}\n\n.box-bouton-formulaire-resultat {\n    margin-top: 2vh;\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
