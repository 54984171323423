// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titre-texte-concret {
    margin-top: 1vh !important;
    text-align: center;
    font-size: 1.5vw !important;
} 

.box-titre-concret {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.action-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 1vw;
    border: 5px solid lightgrey;
}

.box-bordure {
    border: 5px solid lightgrey;
    width: 100%;
}

.box-bordure-max {
    border: 5px solid lightgrey;
    width: 100%;
    height: 37vh;
    max-height: 37vh !important;
    overflow: auto !important;
}

.texte-action-ressource {
    font-size: 0.8vw !important;
    max-width: 35vw;
}

.checkbox-action-ressource {
    transform: scale(1.5);
    color: black !important;
}

.list-action-ressource {
    justify-content: space-around !important;
    border-bottom: 1px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/css/moyenConcret.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,wCAAwC;IACxC,8BAA8B;AAClC","sourcesContent":[".titre-texte-concret {\n    margin-top: 1vh !important;\n    text-align: center;\n    font-size: 1.5vw !important;\n} \n\n.box-titre-concret {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    width: 100%;\n}\n\n.action-box {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    margin: 1vw;\n    border: 5px solid lightgrey;\n}\n\n.box-bordure {\n    border: 5px solid lightgrey;\n    width: 100%;\n}\n\n.box-bordure-max {\n    border: 5px solid lightgrey;\n    width: 100%;\n    height: 37vh;\n    max-height: 37vh !important;\n    overflow: auto !important;\n}\n\n.texte-action-ressource {\n    font-size: 0.8vw !important;\n    max-width: 35vw;\n}\n\n.checkbox-action-ressource {\n    transform: scale(1.5);\n    color: black !important;\n}\n\n.list-action-ressource {\n    justify-content: space-around !important;\n    border-bottom: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
