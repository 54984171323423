// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.box-border {
    border: 5px solid lightgrey;
  }
  
  .h1 {
    margin-top: 4%;
  }
  
  .img {
    width: 20vw;
    height: 15vw;
  }
  
  .form {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: sm;
  }
  
  .button {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .box-form {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 10px;
  }

  .box-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width : 100%;
    margin-top: 50px;
  }`, "",{"version":3,"sources":["webpack://./src/css/connexion.css"],"names":[],"mappings":";;AAEA;IACI,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":["\n\n.box-border {\n    border: 5px solid lightgrey;\n  }\n  \n  .h1 {\n    margin-top: 4%;\n  }\n  \n  .img {\n    width: 20vw;\n    height: 15vw;\n  }\n  \n  .form {\n    margin-top: 1rem;\n    margin-left: 1rem;\n    margin-right: 1rem;\n    width: sm;\n  }\n  \n  .button {\n    margin-top: 3rem;\n    margin-bottom: 2rem;\n  }\n\n  .box-form {\n    display: flex;\n    justify-content: center;\n    flex-direction: row;\n    margin-top: 10px;\n  }\n\n  .box-main {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    width : 100%;\n    margin-top: 50px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
