// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.reponse {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.vrai-faux {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 8vw !important;
}

.option-texte {
    margin-right: 1.5vw !important;
}

.radioButton-vf {
    display: flex;
    justify-content: space-between;
}

.radio-vrai,
.radio-faux {
    margin-right: 2vw !important;
}



.box-sous-titre {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 33%;
    margin-right: 11%;
}

.question-texte-vf {
    max-width: 80%;
    font-size: 1.85vh !important;
}`, "",{"version":3,"sources":["webpack://./src/css/vraiFaux.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;;IAEI,4BAA4B;AAChC;;;;AAIA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,QAAQ;IACR,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,4BAA4B;AAChC","sourcesContent":["\n\n.reponse {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n}\n\n.vrai-faux {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    width: 8vw !important;\n}\n\n.option-texte {\n    margin-right: 1.5vw !important;\n}\n\n.radioButton-vf {\n    display: flex;\n    justify-content: space-between;\n}\n\n.radio-vrai,\n.radio-faux {\n    margin-right: 2vw !important;\n}\n\n\n\n.box-sous-titre {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 33%;\n    margin-right: 11%;\n}\n\n.question-texte-vf {\n    max-width: 80%;\n    font-size: 1.85vh !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
