// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100vw;
    bottom: 0;
    height: 5vh;
    background-color: #FFFFFF;
    padding: 10px 0;
    border-top: 1px solid #000000;
}
  
.footer .logo {
    width: 60%;
    min-width: 125px;
    margin-left: 5%;
}
  
.footer .copyright {
    text-align: right;
    color: #000000;
    margin-right: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".footer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    position: relative;\n    width: 100vw;\n    bottom: 0;\n    height: 5vh;\n    background-color: #FFFFFF;\n    padding: 10px 0;\n    border-top: 1px solid #000000;\n}\n  \n.footer .logo {\n    width: 60%;\n    min-width: 125px;\n    margin-left: 5%;\n}\n  \n.footer .copyright {\n    text-align: right;\n    color: #000000;\n    margin-right: 1vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
