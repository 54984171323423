// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.button-bienvenue {
    margin-top: 5vh !important;
    margin-bottom: 21vh !important;
    width: 10vw;
    font-size: 1.2vw !important;
}

.box-message {
    margin-top: 150px;
}

.texte-bienvenue {
    margin-top: 50px !important;
    max-width: 900px;
    font-size: 20px !important;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/css/bienvenue.css"],"names":[],"mappings":";AACA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["\n.button-bienvenue {\n    margin-top: 5vh !important;\n    margin-bottom: 21vh !important;\n    width: 10vw;\n    font-size: 1.2vw !important;\n}\n\n.box-message {\n    margin-top: 150px;\n}\n\n.texte-bienvenue {\n    margin-top: 50px !important;\n    max-width: 900px;\n    font-size: 20px !important;\n}\n\n.section {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
